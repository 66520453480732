<template>
  <div class="sys-log-wrapper">
    <el-card>
      <el-form slot="header" :model="query" size="small" :inline="true">
        <el-form-item label="日志类型">
          <el-select v-model="query.model">
            <el-option value="警告" label="警告"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table></el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'SystemLog',
  data() {
    return {
      query: {
        model: '警告',
      },
    };
  },
};
</script>

<style></style>
